import request from '@/plugins/axios'

// ================各类统计=====================
export function indexCount() {
  return request({
    url: `/index/count`,
    method: 'get'
    // data: params
  })
}

// 近7天采集数据
export function countDay7() {
  return request({
    url: `/index/countDay7`,
    method: 'get'
    // data: params
  })
}

// 活跃用户
export function userHot() {
  return request({
    url: `/index/userHot`,
    method: 'get'
    // data: params
  })
}

// 活跃群
export function channelHot() {
  return request({
    url: `/index/channelHot`,
    method: 'get'
    // data: params
  })
}

// 热门群组
export function hostGroupApi() {
  return request({
    url: `/hostGroup`,
    method: 'get'
  })
}
// 热门搜索
export function searchKeyApi() {
  return request({
    url: `/searchKey`,
    method: 'get'
  })
}
