<template>
  <div class="reddit-index">
    <div class="rediit-head">
      <div class="head-item" v-for="(item, i) in headArr" :key="i">
        <img :src="item.img" alt="" />
        <div class="text-box">
          <div v-if="!item.bold" class="item-text">
            {{ item.memo1 }}
          </div>
          <div v-else class="item-text">
            <span>{{ item.memo1 }}</span>
            {{ item.measurement }}
          </div>
          <div class="item-text">
            {{ item.memo2 }}
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="reddit-content">
      <div class="left">
        <div class="index-title">
          <div>我的关键词方案</div>
          <span @click="goPath('/monitor/keyword')">更多>></span>
        </div>
        <el-table
          :data="accountList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="280px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5.1px 0' }"
        >
          <el-table-column
            prop="groupName"
            label="方案名称"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="skey" label="关键词"></el-table-column>
          <el-table-column prop="createTime" label="添加时间"></el-table-column>
        </el-table>
      </div>
      <div class="right">
        <div class="index-title">
          <div>最近采集用户</div>
          <span @click="goPath('/search/users')">更多>></span>
        </div>
        <el-table
          v-loading="userLoading"
          :data="communityList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="280px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column
            label="用户头像"
            show-overflow-tooltip
            width="80"
            align="center"
          >
            <template slot-scope="scope">
              <img
                class="left-img"
                :src="
                  scope.row.user_photo
                    ? 'data:image/png;base64,' + scope.row.user_photo
                    : require('@/assets/imgs/p.png')
                "
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column prop="userId" label="用户ID">
            <template slot-scope="scope">
              <span
                @click="userDetail(scope.row.userId)"
                class="index-table-span"
              >
                {{ scope.row.userId }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userLastName" label="姓"></el-table-column>
          <el-table-column prop="userFirstName" label="名"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>

          <!-- <el-table-column prop="hot" label="热度"></el-table-column> -->
        </el-table>
      </div>
      <div class="right1">
        <div class="index-title-img">
          <img :src="require('@/assets/imgs/i11.png')" alt="" />
          <div>热门搜索</div>
        </div>
        <el-table
          :show-header="false"
          v-loading="searchLoading"
          :data="searchList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="280px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column show-overflow-tooltip width="50" align="center">
            <template slot-scope="scope">
              <div v-if="scope.$index == 0" class="index-num index-num1">1</div>
              <div v-else-if="scope.$index == 1" class="index-num index-num2">
                2
              </div>
              <div v-else-if="scope.$index == 2" class="index-num index-num3">
                3
              </div>
              <div v-else>
                {{ scope.$index + 1 }}
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="searchKey"></el-table-column>
        </el-table>
      </div>
      <div class="right2">
        <div class="index-title-img">
          <img :src="require('@/assets/imgs/i12.png')" alt="" />
          <div>推荐群组</div>
        </div>
        <el-table
          :show-header="false"
          v-loading="groupLoading"
          :data="groupList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="280px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column show-overflow-tooltip width="50" align="center">
            <template slot-scope="scope">
              <img
                class="left-img"
                :src="
                  scope.row.avatar
                    ? 'data:image/png;base64,' + scope.row.avatar
                    : require('@/assets/imgs/p.png')
                "
                alt=""
              />
            </template>
          </el-table-column>

          <el-table-column prop="" label="群组名称">
            <template slot-scope="scope">
              <div
                class="group-title-box"
                @click="retrieval(scope.row.groupId)"
              >
                <div class="group-title" :title="scope.row.groupName">
                  {{ scope.row.groupName }}
                </div>
                <div class="group-title">{{ scope.row.groupId }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="reddit-foot">
      <div class="foot-left">
        <div class="index-title">近7天数据采集统计</div>
        <div id="echarts"></div>
      </div>
      <div class="foot-right">
        <div class="index-title">
          <div>最近采集群组</div>
          <span @click="goPath('/search/posts')">更多>></span>
        </div>
        <el-table
          v-loading="channelLoading"
          :data="userList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          :height="windowsHeight - 625"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column
            label="群头像"
            show-overflow-tooltip
            width="80"
            align="center"
          >
            <template slot-scope="scope">
              <img
                class="left-img"
                :src="
                  scope.row.channelPhoto
                    ? 'data:image/png;base64,' + scope.row.channelPhoto
                    : require('@/assets/imgs/p1.png')
                "
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column prop="channelId" label="群号" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                @click="retrieval(scope.row.channelId)"
                class="index-table-span"
              >
                {{ scope.row.channelId }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            prop="channelTitle"
            label="群名称"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="channelUsername"
            label="群用户名"
            show-overflow-tooltip
          ></el-table-column>
          <!-- <el-table-column
            prop="hot"
            label="热度"
            show-overflow-tooltip
          ></el-table-column> -->
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { list as keyList } from '@/api/monitor'
import {
  indexCount,
  countDay7,
  userHot,
  channelHot,
  hostGroupApi,
  searchKeyApi
} from '@/api/index'
import myMixin from '@/layout/mixin/Hook.js'
export default {
  name: 'Index',
  mixins: [myMixin],
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/p.jpg') + '"',
      headArr: [
        {
          img: require('../../assets/imgs/t1.png'),
          memo1: '开通日期：2020-03-01',
          memo2: '到期日期：2020-03-01',
          bold: false
        },
        {
          img: require('../../assets/imgs/t2.png'),
          memo1: 0,
          memo2: '总群数',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t3.png'),
          memo1: 0,
          memo2: '已采集群',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t4.png'),
          memo1: 0,
          memo2: '我的关键词',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t5.png'),
          memo1: '0',
          memo2: '我标记的群组',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t6.png'),
          memo1: '0',
          memo2: '我标记的账号',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t7.png'),
          memo1: '',
          memo2: '',
          bold: false,
          measurement: ''
        }
      ],
      communityList: [],
      communityArr: [],
      accountList: [],
      userList: [],
      userLoading: false,
      channelLoading: false,
      groupList: [], //推荐群组
      groupLoading: false,
      searchList: [], //推荐群组
      searchLoading: false
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    },
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  watch: {
    userInfo: {
      handler(newData, oldData) {
        this.headArr[0].memo1 = '开通日期：' + newData.createTime
        this.headArr[0].memo2 = '结束日期：' + newData.expireTime

        this.headArr[6].memo1 =
          newData.isYs == 1
            ? '总 次 数：' + newData.totalScore
            : '总积分：' + newData.totalScore

        this.headArr[6].memo2 =
          newData.isYs == 1
            ? '剩余次数：' + newData.score
            : '剩余积分：' + newData.score
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {
    // 获取头部6个数据
    this.getHead()
    // 获取折线图的数据
    this.getEcharts()
    //关键词
    this.getKey()
    // 活跃用户
    this.getUserHot()
    //活跃群
    this.getchannelHot()
    //
    this.getGroup()
    this.getSearchKe()
  },
  methods: {
    getGroup() {
      this.groupLoading = true
      hostGroupApi()
        .then(res => {
          this.groupList = res
          this.groupLoading = false
        })
        .catch(err => {
          // 请求失败
          this.groupLoading = false
        })
    },
    getSearchKe() {
      this.searchLoading = true
      searchKeyApi()
        .then(res => {
          this.searchList = res
          this.searchLoading = false
        })
        .catch(err => {
          // 请求失败
          this.searchLoading = false
        })
    },
    goPath(path) {
      this.$router.push(path)
    },

    getKey() {
      keyList(1, 7)
        .then(res => {
          this.accountList = res.items
        })
        .catch(err => {
          // 请求失败
          console.log(err)
        })
    },

    getUserHot() {
      this.userLoading = true
      userHot().then(res => {
        this.communityList = res
        this.userLoading = false
      })
    },
    getchannelHot() {
      this.channelLoading = true
      channelHot().then(data => {
        this.userList = data
        this.channelLoading = false
      })
    },
    getHead() {
      indexCount().then(res => {
        this.headArr[1].memo1 = res.channelTotalCount
        this.headArr[2].memo1 = res.channelCount
        this.headArr[3].memo1 = res.keyCount
        this.headArr[4].memo1 = res.tipChannelCount
        this.headArr[5].memo1 = res.userRemarkCount
      })
    },
    getEcharts() {
      countDay7().then(data => {
        this.getEchartsLine(
          'echarts',
          data.day,
          data.channel,
          data.user,
          data.message
        )
      })
    },
    getEchartsLine(dom, dataX, datay1, datay2, datay3) {
      const chart = echarts.init(document.getElementById(dom))
      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['新增群数', '新增账号', '新增消息数']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dataX
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '新增群数',
            type: 'line',
            data: datay1,
            smooth: true
          },
          {
            name: '新增账号',
            type: 'line',
            data: datay2,
            smooth: true
          },
          {
            name: '新增消息数',
            type: 'line',
            data: datay3,
            smooth: true
          }
        ]
      }
      window.onresize = function() {
        chart.resize()
      }
      chart.setOption(option)
    },
    // 人员检索
    userDetail(userId) {
      this.$router.push('/user/detail/' + userId)
    },
    // 群组检索
    retrieval(id) {
      this.$router.push('/group/detail/' + id)
    }
  }
}
</script>

<style lang="scss" scoped>
.reddit-index {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px 20px;
  background: rgb(240, 242, 245);

  .rediit-head {
    width: 100%;
    height: 141px;
    background: white;
    border-radius: 5px;
    display: flex;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    .head-item {
      width: calc(100% / 7 - 1px);
      height: 131px;
      border-right: 1px solid #f2f4f8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      &:nth-last-child(1) {
        border-right: none;
      }
      img {
        height: auto;
        object-fit: cover;
        align-self: center;
      }
      .text-box {
        align-self: center;
        margin-top: 13px;
        text-align: center;
        .item-text {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-size: 22px;
          }
        }
      }
    }
  }
  .reddit-content {
    height: 337px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      width: 33.5%;
      height: 100%;
      background: #ffffff;
      border-radius: 5px;
      padding: 5px;
      overflow: hidden;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
    .right {
      width: 35%;
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 5px;
        vertical-align: middle;
      }
    }
    .right1,
    .right2 {
      width: 15%;
      background: #ffffff;
      border-radius: 5px;
      padding: 5px;
      overflow: hidden;
      box-shadow: 0 1px 3px 0 #d8d9d9;
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 5px;
        vertical-align: middle;
      }
      .index-num {
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        color: white;
      }
      .index-num1 {
        background: url('../../assets/imgs/i13.png') no-repeat center;
        background-size: 100% auto;
      }
      .index-num2 {
        background: url('../../assets/imgs/i14.png') no-repeat center;
        background-size: 100% auto;
      }
      .index-num3 {
        background: url('../../assets/imgs/i15.png') no-repeat center;
        background-size: 100% auto;
      }
      .group-title-box {
        cursor: pointer;
        user-select: none;
        .group-title {
          font-size: 12px;
          overflow: hidden; //超出隐藏
          white-space: nowrap; //不折行
          text-overflow: ellipsis; //溢出显示省略号
        }
        &:hover {
          .group-title {
            color: #0b6ad6;
          }
        }
      }
    }
  }
  .reddit-foot {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    > div {
      background: white;
      border-radius: 5px;
      height: 100%;
      padding: 5px;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
    .foot-left {
      width: 49.5%;
    }
    .foot-right {
      width: 50%;
      .left-img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    }
    #echarts {
      width: 100%;
      height: calc(100vh - 615px);
    }
  }
  .index-title {
    display: flex;
    justify-content: space-between;
    text-indent: 9px;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 24px;
    border-bottom: 1px solid #f4f4f4;
    padding: 5px 0;
    font-weight: 600;
    span {
      color: #7c7b7b;
      cursor: pointer;
      &:hover {
        color: #0b6ad6;
      }
    }
  }
  .index-title-img {
    display: flex;

    text-indent: 9px;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 24px;
    border-bottom: 1px solid #f4f4f4;
    padding: 5px 0;
    font-weight: 600;
    span {
      color: #7c7b7b;
      cursor: pointer;
      &:hover {
        color: #0b6ad6;
      }
    }
    img {
      margin-left: 10px;
      width: 22px !important;
      height: 22px !important;
      object-fit: cover;
    }
  }
  // ::v-deep .el-table__header .el-table__cell {
  //   background: rgb(243, 247, 249);
  // }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 0;
    height: 35px;
  }
  .table-cell-img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    vertical-align: middle;
  }
  // 下划线
  .index-table-span {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: #0b6ad6;
    }
  }
}
</style>
