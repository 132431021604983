export default {
  computed: {
    tabRouterList() {
      return this.$store.state.settings.tabs
    }
  },
  beforeRouteLeave(to, from, next) {
    let path = this.$route.path

    if (Array.isArray(this.tabRouterList) && this.tabRouterList.length > 0) {
      if (
        !this.tabRouterList.some(item => {
          return item.path == path
        })
      ) {
        this.$destroy(this.$options.name)
      }
    }
    next()
  }
}
